// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.darkmode .multiselect__tags {
  background: #36404a;
  border: none;
}
.darkmode .multiselect__content-wrapper {
  background: #4d5a66;
  border-color: #4d5a66;
  color: #e5eeff;
}
.multiselect__content ul li span {
  color: red;
}
.darkmode .multiselect__input {
  color: #e5eeff;
  background: #4d5a66;
}
.darkmode .multiselect__input {
  color: #e5eeff;
  background: #4d5a66;
}
.darkmode .multiselect__input::placeholder {
  color: #939393;
}
.darkmode .multiselect__option {
  color: #e5eeff;
}
.darkmode .multiselect__single {
  color: #e5eeff;
  background: #4d5a66;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
