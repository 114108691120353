<template>
  <div style="width: 330px">
    <div
      class="message-welcome-center"
      v-if="contacts.length == 0 && !isSearching"
    >
      <p>
        Digite o nome ou telefone do
        <span class="text-highlight">contato</span> que deseja buscar!
      </p>
    </div>
    <div class="message-welcome-center" v-if="isSearching">
      <p>
        <i
          class="fas fa-spinner fa-spin text-highlight"
          style="font-size: 40pt"
        ></i>
      </p>
    </div>
    <div
      class="message-welcome-center"
      v-if="!isSearching && query !== '' && contacts.length == 0"
    >
      <p>
        Nada foi encontrado para
        <span class="text-highlight">{{ query }}...</span>
      </p>
    </div>
    <ul
      class="user-list"
      v-for="contact in contacts"
      v-bind:key="contact?.id_api"
    >
      <li class="user-list-item" v-if="!isSearching">
        <div
          class="avatar"
          :class="{
            'avatar-online': contact.isOnline == true,
            'avatar-telegram': contact.contact_type === 'telegram_bot',
            'avatar-instagram': contact.contact_type === 'instagram',
            'avatar-facebook': contact.contact_type === 'facebook',
            'avatar-twitter': contact.contact_type === 'twitter',
          }"
        >
          <img
            v-lazy="{
              src:
                typeof contact.profilePicUrl == 'string'
                  ? contact.profilePicUrl
                  : errorOnProfilePic(),
              error: errorOnProfilePic(),
            }"
            class="rounded-circle"
            alt="image"
          />
        </div>
        <div
          class="users-list-body align-items-center"
          v-on:click="$emit('openChat', contact)"
        >
          <div>
            <h5 class="text-ellipsis-name">{{ contact.name }}</h5>
            <template v-if="contact.pushname">
              <span><b>Interno:</b> {{ contact.pushname }}</span>
            </template>
            <span>{{
              contact.id_api.replace(
                /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s\.whatsapp\.net)/,
                "($2) $3-$4"
              )
            }}</span>
          </div>
        </div>

        <div
          class="transfer-badge"
          v-if="contact.userIdOpened && contact.userIdOpened !== data.user.id"
        >
          <span class="text-transfer"
            >Em chat com
            {{ data.getUserById(contact.userIdOpened)?.name }}</span
          >
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { db } from "../../dbs/db";

import { useStore } from "../../stores/dataStore";
import { ChatCollection } from "../../stores/chatsStore";

export default defineComponent({
  props: {
    query: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      data: useStore(),
      db: db,
      ChatCollection,
    };
  },
  watch: {
    query(newest: string) {
      this.isSearching = newest.length > 0;
      clearTimeout(this.timeTyping);
      this.timeTyping = setTimeout(async () => {
        this.findContact();
      }, 200);
    },
  },
  data() {
    return {
      baseURL: this.$api + "/",
      isSearching: false,
      isTyping: true,
      timeTyping: null as any,
      contacts: [] as any,
      startContacts: [] as any[],
    };
  },
  async mounted() {
    let contacts = await this.db.contacts.toArray();
    if (contacts.length > 0) {
      contacts.sort((a: any, b: any) =>
        (a?.name as any)?.localeCompare(b?.name)
      );
      contacts = contacts.filter(
        (contact) =>
          !contact.id_api?.includes("@g.us") &&
          !this.ChatCollection().get(contact.id)
      );
      this.contacts = contacts?.slice(0, 40);
      this.startContacts = contacts?.slice(0, 40);
    } else {
      this.contacts = [];
      this.startContacts = [];
    }
  },
  methods: {
    async findContact() {
      if (this.query == "") {
        this.isSearching = false;
        this.contacts = this.startContacts;
        return;
      } else {
        try {
          const normalizeAndLowercase = (texto: string): string => {
            if (!texto || texto == "") return "";
            return texto
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
          };
          const all = await this.db.contacts.toArray();
          const filtered = all.filter((contact) => {
            const searchWords = normalizeAndLowercase(this.query).split(" ");
            return (
              !contact.id_api?.includes("@g.us") &&
              this.ChatCollection()?.get(contact.id)?.userIdOpened !==
                this.data.user.id &&
              searchWords.every((word) => {
                return (
                  normalizeAndLowercase(contact.name as any)?.includes(
                    normalizeAndLowercase(word)
                  ) ||
                  normalizeAndLowercase(contact.pushname as any)?.includes(
                    normalizeAndLowercase(word)
                  ) ||
                  normalizeAndLowercase(contact.id_api as any)?.includes(
                    normalizeAndLowercase(word)
                  )
                );
              })
            );
          });
          filtered.sort((a: any, b: any) => {
            if (a?.timestamp !== b?.timestamp) {
              return b?.timestamp - a?.timestamp;
            }
            // Se os timestamps são iguais, ordena pelo nome
            return a?.name.localeCompare(b?.name);
          });
          this.contacts = filtered;
          this.isSearching = false;
        } catch (error: any) {
          throw new Error(error);
        }
      }
    },
    errorOnProfilePic() {
      return userNoPhotoUrl;
    },
  },
});
</script>
<style>
.text-ellipsis-name {
  /* Defina a largura máxima para o elemento */
  width: 200px; /* Altere para a largura desejada */

  /* Define o overflow para ocultar o conteúdo excedente */
  overflow: hidden;

  /* Define o comportamento do texto */
  white-space: nowrap;

  /* Adiciona as reticências (...) quando o texto é cortado */
  text-overflow: ellipsis;
}
</style>
