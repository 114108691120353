// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.text-ellipsis-name {
  /* Defina a largura máxima para o elemento */
  width: 200px; /* Altere para a largura desejada */

  /* Define o overflow para ocultar o conteúdo excedente */
  overflow: hidden;

  /* Define o comportamento do texto */
  white-space: nowrap;

  /* Adiciona as reticências (...) quando o texto é cortado */
  text-overflow: ellipsis;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
