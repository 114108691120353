<script lang="ts">
import { defineComponent } from "vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { maska } from "maska";
import { Companies } from "../../../stores/Companies";
import Swal from "sweetalert2";
import { api } from "../../../service/api";

export default defineComponent({
  directives: { maska },
  components: {
    Multiselect,
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
    companyId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    async companyId(companyId: any) {
      if (companyId) {
        const company = await this.Companies().findById(companyId);
        this.companies = this.Companies().items as any;
        this.selected = company as any;
      } else {
        this.selected = null;
      }
    },
  },
  data() {
    return {
      companies: [],
      selected: null,
    };
  },
  setup() {
    return {
      Companies,
    };
  },
  async mounted() {
    try {
      if (this.companyId) {
        const company = await this.Companies().findById(this.companyId);
        this.companies = this.Companies().items as any;
        this.selected = company as any;
      }
    } catch (error) {
      //nothing
    }
  },
  methods: {
    addTag(e: any) {
      const i = {
        id: 0,
        fantasyName: e,
        email: "",
        teleResidencial: "",
        docNumber: "",
        whatsapp: "",
      } as any;
      (this.companies as any).push(i as any);
      this.selected = i;
    },
    async triggerSelect() {
      if ((this.selected as any).id) {
        api.post(`/contacts/update/${this.contactId}`, {
          companyId: (this.selected as any).id,
        });
      }
    },
    async asyncFind(e: any) {
      try {
        this.companies = (await this.Companies().find(e)) as any;
      } catch (error) {
        //nothing
      }
    },
    cancelCompany() {
      this.selected = null;
    },
    async addNewCompany(e: any) {
      e.preventDefault();
      Swal.showLoading();
      try {
        const result = await this.Companies().create({
          id: undefined,
          teleResidencial: (this.selected as any)?.teleResidencial?.replace(
            /[^0-9]/g,
            ""
          ),
          docNumber: (this.selected as any)?.docNumber?.replace(/[^0-9]/g, ""),
          fantasyName: (this.selected as any)?.fantasyName,
          whatsApp: (this.selected as any)?.whatsapp?.replace(/[^0-9]/g, ""),
          email: (this.selected as any)?.email,
        });
        this.companies = this.companies.filter(
          (company: any) => company.id !== 0
        );
        (this.companies as any).push(result);
        (this as any).selected = result;
        this.triggerSelect();
        Swal.close();
      } catch (error) {
        Swal.close();
      }
    },
  },
});
</script>
<template>
  <multiselect
    placeholder="Digite o nome da empresa"
    v-model="selected"
    :options="companies"
    label="fantasyName"
    :taggable="true"
    selectLabel="Selecionar"
    selectedLabel="Selecionada"
    tagPlaceholder="Criar nova empresa"
    deselectLabel="Remover"
    @select="triggerSelect"
    @tag="addTag"
    @search-change="asyncFind"
    ><template v-slot:singleLabel="{ option }"
      ><strong>{{ option.fantasyName }}</strong></template
    >
  </multiselect>
  <div class="row" v-if="selected?.id === 0">
    <form @submit="addNewCompany">
      <div class="form-group mb-1">
        <label>Email</label>
        <input
          class="form-control form-control-lg group_formcontrol"
          v-model="(selected as any).email"
          name="name"
          type="email"
        />
      </div>
      <div class="form-group mb-1">
        <label>CPF/CNPJ</label>
        <input
          class="form-control form-control-lg group_formcontrol"
          v-model="(selected as any).docNumber"
          v-maska="['###.###.###-##', '##.###.###/####-##']"
          name="docNumber"
          type="text"
        />
      </div>
      <div class="form-group mb-1">
        <label>Telefone</label>
        <input
          class="form-control form-control-md group_formcontrol"
          v-model="(selected as any).teleResidencial"
          v-maska="['(##) ####-####', '(##) #####-####']"
          name="surname"
          type="text"
        />
      </div>
      <div
        class="form-row profile_form text-end float-end m-0 align-items-center"
      >
        <!-- Button -->
        <div class="cancel-btn me-4">
          <a
            href="javascript:void(0);"
            @click="cancelCompany"
            aria-label="Close"
            >Cancelar</a
          >
        </div>
        <div class="">
          <button type="submit" class="btn btn-block newgroup_create mb-0">
            Adicionar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<style>
.darkmode .multiselect__tags {
  background: #36404a;
  border: none;
}
.darkmode .multiselect__content-wrapper {
  background: #4d5a66;
  border-color: #4d5a66;
  color: #e5eeff;
}
.multiselect__content ul li span {
  color: red;
}
.darkmode .multiselect__input {
  color: #e5eeff;
  background: #4d5a66;
}
.darkmode .multiselect__input {
  color: #e5eeff;
  background: #4d5a66;
}
.darkmode .multiselect__input::placeholder {
  color: #939393;
}
.darkmode .multiselect__option {
  color: #e5eeff;
}
.darkmode .multiselect__single {
  color: #e5eeff;
  background: #4d5a66;
}
</style>
