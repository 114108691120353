<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import userNoPhotoUrl from "../assets/img/profile-photo.jpg";

import { socket } from "../service/socket";
import { isMobile } from "../helpers/funcs";
import { config } from "../public/config";
import { useStore } from "../stores/dataStore";
import { ChatCollection } from "../stores/chatsStore";
import { sendFile, sendLocation, sendButtons } from "../helpers/message";
import moment from "moment";

import ChatItemComponent from "./items/chatItemComponent.vue";
import ChatPlaceholderComponent from "./items/chatPlaceholderComponent.vue";
import SyncAlert from "./items/SyncAlert.vue";
import ChatLayout from "./chats/chat.vue";
import RightSidebar from "./chats/right-sidebar.vue";
import ModalTransferLayout from "./chats/modal-transfer-chat.vue";
import ModalNewChatContact from "./chats/modal-new-chat-contact.vue";
import ModalNewContact from "./modal/new-contact.vue";
import ModalAnnouncement from "./modal/announcement.vue";
import ContactsFilterComponent from "./Contacts/ContactsFilterComponent.vue";
import Stat from "./chats/widgets/Stat.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PerfectScrollbar,
    ChatItemComponent,
    ChatPlaceholderComponent,
    ChatLayout,
    RightSidebar,
    ModalTransferLayout,
    ModalNewChatContact,
    ModalNewContact,
    ModalAnnouncement,
    Stat,
    ContactsFilterComponent,
    SyncAlert,
  },
  setup() {
    const data = useStore();

    return {
      data,
      socket,
      ChatCollection,
      User: () => useStore(),
      defaultPic: userNoPhotoUrl,
      isMobile: isMobile,
    };
  },
  unmounted() {
    this.User().openedChat = null;
  },
  data() {
    return {
      $api: this.$api,

      chat_opened: "",
      image_url: "",
      apiURL: config.$api_url + "/",
      search: "",
      stats: {
        inChat: 0,
        waiting: 0,
        timeToResponse: 5,
      },
      onlyChatNotReplyed: false,
      onlyGroups: false,
      allChats: false,
      allChatsWithGroups: false,
    };
  },
  async mounted() {
    //if (Capacitor.isNativePlatform()) {
    //const addListeners = async () => {
    /*await PushNotifications.addListener("registration", (token) => {
          console.info("Registration token: ", token.value);
        });

        await PushNotifications.addListener("registrationError", (err) => {
          console.error("Registration error: ", err.error);
        });

        await PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push notification received: ", notification);
          }
        );

        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            console.log(
              "Push notification action performed",
              notification.actionId,
              notification.inputValue
            );
          }
        );*/

    /**
     * Quando estiver em um dispositivo móvel (e ele voltar a coneccção, ele irá atualizar os chats)
     */
    /*App.addListener("appStateChange", ({ isActive }) => {
          if (isActive) {
            this.User().syncChats();
          }
        });*/
    //};

    /*const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }

        await PushNotifications.register();
      };
      registerNotifications();
      addListeners();*/
    //}
    this.socket.on("contact:revoked", (event) => {
      if (
        this.data.openedChat?.id_api === event?.chatId &&
        event.userId !== this.data.user.id
      ) {
        this.closeWindowChat();
      }
    });
    if (this.$route?.query?.chatId) {
      this.data.openedChat = await this.data.getContact(
        this.$route?.query?.chatId
      );
    }

    //Rightside accordian
    $(".accordion-col .accordion-title").on("click", function () {
      $(this).next().slideToggle();
      $(this).toggleClass("active");
    });
    //Custom modal click for status view
    $('*[data-target="#status-modal"]').on("click", function () {
      $("body").addClass("custom-model-open");
    });
    $(".custom-status-close").on("click", function () {
      $("body").removeClass("custom-model-open");
    });

    // Busca de chats
    $("#search-contacts").on("keyup", function () {
      document.getElementById("nav-all")?.click();
      const value = $(this).val().toLowerCase().normalize("NFD");
      $(".sidebar-body ul li").filter(function () {
        $(this).toggle(
          $(this)
            .text()
            .toLowerCase()
            .replace("-", "")
            .normalize("NFD")
            .indexOf(value) > -1
        );
      });
    });
    this.showAnnounce();
  },
  methods: {
    openChat(contact) {
      this.clearSearch("search-contacts");
      contact.isOpen = true;
      contact.userIdOpened = this.data.user.id;
      this.search = "";
      this.data.openedChat = contact;
      this.data.assignChatToMe(contact);
    },
    async showAnnounce() {
      this.data.getUserData().then(() => {
        if (!this.data.user.readAnnounce) {
          document.getElementById("clickToShowAnnounces").click();
        }
      });
    },
    closeWindowChatRequest(data) {
      this.closeWindowChat(data.contactId);
    },
    closeWindowChat(contactId) {
      if (this.data.openedChat?.id === contactId) {
        this.data.clearActiveChat();
        this.reply = "";
        this.data.forward_msgId = "";
      }
    },
    setMessages(chat) {
      //this.data.openedChat = chat;
      if ($(window).width() < 992) {
        //const chat = document.getElementsByClassName("chat")[0];
        //const leftSidebar = document.getElementsByClassName("left-sidebar")[0];
        //const sidebarMenu = document.getElementsByClassName("sidebar-menu")[0];
        //chat.classList.add("show-chatbar");
        //leftSidebar.style.display = "none";
        //leftSidebar.classList.add("hide-left-sidebar");
        //sidebarMenu.classList.add("hide-left-sidebar");
        //$(".left-sidebar").addClass("hide-left-sidebar");
        //$(".chat").addClass("show-chatbar");
        //$(".sidebar-menu").toggle();
      }
      /*this.clearSearch("search-contacts");
      this.reply = "";*/
      /*const changes = [{ name: "BlankunreadCount", value: true }];
      this.data.editChat(chat.id_api, changes).then(() => {
        this.bottomScroolbar("chat_messages");
        /*if (!isMobile()) {
          document?.getElementById("inputText")?.focus();
          $("#chat_messages").animate(
            {
              opacity: 0,
            },
            0,
            function () {
              // Animation complete.
            }
          );
          $("#chat_messages").animate(
            {
              opacity: 1,
            },
            800,
            function () {
              // Animation complete.
            }
          );
        }
      });*/
    },
    /*callsendContact(channelType, chatId, contact, replyData, isGroupMsg = false){
            sendContact(channelType, chatId, contact, replyData, isGroupMsg)
        },*/
    callsendFile(
      channelType,
      channelId = 0,
      type,
      chatId,
      file = File,
      caption,
      base64 = null,
      replyData = null,
      isGroupMsg = Boolean
    ) {
      sendFile(
        channelType,
        channelId,
        type,
        chatId,
        file,
        caption,
        base64,
        replyData,
        isGroupMsg,
        false
      );
    },
    callsendLocation(
      channelType = String,
      channelId = 0,
      chatId = String,
      lat,
      lng,
      replyData,
      isGroupMsg = false
    ) {
      sendLocation(
        channelType,
        channelId,
        chatId,
        lat,
        lng,
        replyData,
        isGroupMsg
      );
    },
    callsendButtons(
      channelType = String,
      channelId = 0,
      chatId = String,
      contactId,
      title,
      footer,
      content,
      buttons,
      replyData
    ) {
      sendButtons(
        channelType,
        channelId,
        chatId,
        contactId,
        title,
        footer,
        content,
        buttons,
        replyData,
        false
      );
    },
    bottomScroolbar(divId) {
      setTimeout(() => {
        const objDiv = document.getElementById(divId);
        if (!objDiv.scrollTop && objDiv.scrollTop != 0) return;
        objDiv.scrollTop = objDiv?.scrollHeight;
      }, 150);
    },
    clearSearch(id) {
      $("#" + id).val("");
      let divList;
      if (id == "search-contacts") {
        divList = ".sidebar-body .user-list li";
        this.search = "";
      }
      if (id == "search-contact-forward") divList = "#perfect-forward ul li";
      if (id == "search-new-chat") divList = "#perfect-new-chat ul li";
      if (id == "search-send-contact") divList = "#perfect-send-contact ul li";

      const value = "";
      $(divList).filter(function () {
        $(this).toggle(
          $(this).text().toLowerCase().replace("-", "").indexOf(value) > -1
        );
      });
    },
    getAdminLinkPage() {
      return atob("L3NpdGUtYWRtaW4vaW5kZXg=", "base64");
    },
    filterChats(e) {
      const id = e.target.id;
      const els = document.getElementsByClassName("nav-chat");
      for (const el of els) {
        el.classList.remove(["active"]);
      }
      e.target.classList.add(["active"]);
      id.includes("notresponse")
        ? (this.onlyChatNotReplyed = true)
        : (this.onlyChatNotReplyed = false);
      id.includes("groups")
        ? (this.onlyGroups = true)
        : (this.onlyGroups = false);
      id.includes("allchats-admin")
        ? (this.allChats = true)
        : (this.allChats = false);
      id.includes("allchats-user")
        ? (this.allChatsWithGroups = true)
        : (this.allChatsWithGroups = false);
    },
    profilePicUrl(url) {
      if (!url) {
        return this.defaultPic;
      } else {
        return this.apiURL + url;
      }
    },
    tooggleActionsChat(e) {
      const el = e.target;
      const block = document.getElementById("chat-users-filters");
      if (el.classList.contains("fa-arrow-up")) {
        el.classList.remove(["fa-arrow-up"]);
        el.classList.add(["fa-arrow-down"]);
        block.style.display = "none";
      } else {
        el.classList.remove(["fa-arrow-down"]);
        el.classList.add(["fa-arrow-up"]);
        block.style.display = "block";
      }
    },
  },
  computed: {
    getChatsDepartment() {
      if (
        !this.ChatCollection().items ||
        this.ChatCollection().items?.length === 0
      )
        return [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const chatsSort = this.ChatCollection().items?.sort((x, y) => {
        return y?.timestamp - x?.timestamp;
      });
      return chatsSort.filter(
        (chat) => chat?.departmentId > 0 && !chat?.id_api?.includes("@g.us")
      );
    },
    getChatsBot() {
      if (
        !this.ChatCollection().items ||
        this.ChatCollection().items?.length === 0
      )
        return [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const chatsSort = this.ChatCollection().items?.sort(
        (x, y) => y?.timestamp - x?.timestamp
      );
      return chatsSort.filter(
        (chat) =>
          chat?.isOpen == true &&
          chat?.departmentId == 0 &&
          !chat?.id_api?.includes("@g.us") &&
          (chat?.userIdOpened == 0 || !chat?.userIdOpened)
      );
    },
    getMyChats() {
      if (
        !this.ChatCollection().items ||
        this.ChatCollection().items?.length === 0
      )
        return [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let chatsSort = this.ChatCollection().items?.sort(function (x, y) {
        return y?.timestamp - x?.timestamp;
      });
      if (this.onlyChatNotReplyed) {
        return chatsSort?.filter(
          (chat) =>
            !chat?.Messages[chat?.Messages?.length - 1]?.userIdSend &&
            chat?.userIdOpened == this.data?.user?.id &&
            !chat?.id_api.includes("@g.us")
        );
      } else if (this.onlyGroups) {
        return chatsSort?.filter((chat) => chat?.id_api.includes("@g.us"));
      } else if (this.allChatsWithGroups) {
        return chatsSort.filter(
          (chat) =>
            chat?.userIdOpened?.toString() == this.data?.user?.id?.toString() ||
            chat?.id_api.includes("@g.us")
        );
      } else if (this.allChats) {
        return chatsSort?.filter(
          (chat) =>
            (chat?.userIdOpened != 0 && chat?.userIdOpened != null) ||
            !chat?.id_api.includes("@g.us")
        );
      } else {
        chatsSort = chatsSort.filter(
          (chat) =>
            chat?.userIdOpened?.toString() == this.data?.user?.id?.toString() &&
            !chat?.id_api.includes("@g.us")
        );
        return chatsSort;
      }
    },
    timeWaiting() {
      if (
        !this.ChatCollection().items ||
        this.ChatCollection().items?.length === 0
      )
        return "00:00";
      const contacts = [
        ...this.ChatCollection().items.filter(
          (chat) =>
            chat?.userIdOpened == this.data?.user?.id &&
            !chat?.id_api?.includes("@g.us")
        ),
        ...this.ChatCollection().items.filter(
          (chat) =>
            chat?.isOpen === true &&
            chat?.userIdOpened === 0 &&
            !chat?.id_api?.includes("@g.us")
        ),
      ];
      let qnt = 0;
      let timeAccumulate = 0;
      let timeMinutes = 0;
      if (contacts.length === 0 || !contacts[0]?.id) return "00:00";
      for (const contact of contacts) {
        if (!contact?.Messages || contact?.Messages?.length == 0) continue;
        const getMessageByKey = (n) => {
          return contact?.Messages[contact?.Messages?.length - n];
        };
        // A ultima mensagem foi encontrada e tem um usuario que enviou
        if (getMessageByKey(1).userIdSend !== null) {
          let msgCustomer = null;
          let msgUser = getMessageByKey(1);
          for (let i = 2; i < contact.Messages.length; i++) {
            if (!getMessageByKey(i).userIdSend) {
              msgCustomer = getMessageByKey(i);
              msgUser = getMessageByKey(i - 1);
              break;
            }
          }
          if (msgCustomer !== null) {
            timeAccumulate += Math.round(
              (msgUser.timestamp - msgCustomer.timestamp) / 60
            );
            qnt += 1;
          }
        } else {
          timeAccumulate += Math.round(
            (moment().unix() - getMessageByKey(1).timestamp) / 60
          );
          qnt += 1;
        }
      }
      timeMinutes = timeAccumulate / qnt;
      if (timeMinutes === 0) return "00:00";
      const h = Math.floor(timeMinutes / 60),
        m = timeMinutes % 60;
      const horasFormatadas = h < 10 ? "0" + h : h.toString();
      const minutosFormatados =
        parseInt(m) < 10 ? "0" + parseInt(m) : parseInt(m).toString();
      if (isNaN(horasFormatadas) || isNaN(minutosFormatados)) return "00:00";

      return horasFormatadas + ":" + minutosFormatados;
    },
  },
});
</script>
<template>
  <div>
    <div class="main-wrapper">
      <!-- content -->
      <div class="content main_content">
        <layout-sidebar></layout-sidebar>

        <!-- sidebar group -->
        <div class="sidebar-group left-sidebar chat_sidebar">
          <!-- Chats sidebar -->
          <div id="chats" class="left-sidebar-wrap sidebar active">
            <div>
              <perfect-scrollbar class="scroll-area">
                <!-- Left Chat Title -->
                <div
                  class="left-chat-title d-flex justify-content-between align-items-center"
                >
                  <div class="chat-title">
                    <h4>CHATS</h4>
                  </div>
                  <div class="add-section">
                    <ul>
                      <li v-if="data.user.isSiteAdmin">
                        <a
                          :href="getAdminLinkPage()"
                          id="site-admin"
                          target="_blank"
                          style="width: 90px; background-color: #ee00ab"
                        >
                          Site Admin
                        </a>
                      </li>
                      <!--<li><router-link to="/group"><span class="material-icons">group</span></router-link></li>-->
                      <li>
                        <a
                          href="javascript:void(0);"
                          id="add-contact-buttom"
                          data-bs-toggle="modal"
                          data-bs-target="#add-user"
                          ><i
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Novo contato"
                            class="fas fa-plus"
                          ></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /Left Chat Title -->

                <div class="search_chat has-search">
                  <form @submit="(e) => e.preventDefault()">
                    <span class="fas fa-search form-control-feedback"></span>
                    <input
                      class="form-control chat_input"
                      id="search-contacts"
                      v-model="search"
                      type="text"
                      placeholder="Buscar chats & contatos"
                    />
                    <button
                      type="reset"
                      id="reset"
                      v-on:click="clearSearch('search-contacts')"
                    >
                      &times;
                    </button>
                  </form>
                </div>
                <transition appear name="slide-fade">
                  <ul
                    class="user-list mt-2 boxConnection channel"
                    v-if="data.channels.length === 0"
                  >
                    <span class="material-icons" style="font-size: 48px"
                      >cloud_off</span
                    >
                    Por favor, cadastre um canal
                    <br />
                    <small
                      >Só assim, você poderá usar todas as facilidades do Titan
                      Chat!</small
                    >
                  </ul>
                </transition>
                <transition appear name="slide-fade">
                  <ul
                    class="user-list mt-2 boxConnection socket"
                    v-if="!data.readyConnection"
                  >
                    <span class="material-icons" style="font-size: 48px"
                      >cloud_off</span
                    >
                    Por favor, conecte-se a internet
                    <br />
                  </ul>
                </transition>
                <template v-for="chn in data.channels">
                  <ul
                    class="user-list mt-2 boxConnection channel"
                    style="margin-bottom: 20px"
                    v-bind:key="chn.id"
                    v-if="!chn.isConnected && chn.type === 'whatsapp'"
                  >
                    <span class="material-icons" style="font-size: 48px"
                      >link_off</span
                    >O canal
                    {{
                      chn.type
                    }}
                    está desconectado<br />
                  </ul>
                </template>
                <SyncAlert />
                <div
                  class="left-chat-title mb-0"
                  style="float: right; margin-top: -20px; display: none"
                >
                  <div class="add-section mb-0">
                    <a
                      href="javascript:void(0);"
                      style="font-size: 8pt; background-color: #3e4a56"
                      ><i
                        class="fas fa-arrow-up"
                        @click="tooggleActionsChat"
                      ></i
                    ></a>
                  </div>
                </div>
                <div id="chat-users-filters" style="margin-top: -20px">
                  <div class="top-online-contacts" style="display: none">
                    <div
                      class="swiper-container swiper-container-initialized swiper-container-horizontal"
                    >
                      <div
                        class="swiper-wrapper"
                        style="transform: translate3d(0px, 0px, 0px)"
                      >
                        <div
                          class="swiper-slide swiper-slide-active"
                          style="width: 71px; margin-right: 15px"
                          v-for="item of data.users.filter(
                            (usr) => usr.id !== data.user.id
                          )"
                          :key="item.id"
                        >
                          <div class="top-contacts-box">
                            <div
                              class="profile-img"
                              style="cursor: pointer"
                              :class="{ online: item.isOnline }"
                            >
                              <img
                                style="
                                  height: 60px;
                                  width: 60px;
                                  object-fit: cover;
                                "
                                v-lazy="{
                                  src: !profilePicUrl(item.profilePicUrl)
                                    ? defaultPic
                                    : profilePicUrl(item.profilePicUrl),
                                  error: defaultPic,
                                }"
                                alt=""
                              />
                            </div>
                            <div class="profile-name">
                              <span>{{ item.name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sidebar-body" id="chatsidebar">
                  <div class="nav chat-scroll side_bar">
                    <a
                      class="nav-chat nav-item nav-link"
                      @click="filterChats"
                      v-show="data.hasPermission('read-all-chats')"
                      id="nav-allchats-admin"
                      >Todos</a
                    >
                    <a
                      class="nav-chat nav-item nav-link"
                      @click="filterChats"
                      v-show="!data.user.master_account"
                      id="nav-allchats-user"
                      >Tudo</a
                    >
                    <a
                      class="nav-chat nav-item nav-link active"
                      @click="filterChats"
                      id="nav-all"
                      >Meus chats</a
                    >
                    <a
                      class="nav-chat nav-link"
                      id="nav-notresponse"
                      @click="filterChats"
                      >Não respondidos</a
                    >
                    <a
                      class="nav-chat nav-link"
                      id="nav-groups"
                      v-show="data.hasPermission('read-all-chats-groups')"
                      @click="filterChats"
                      >Grupos</a
                    >
                  </div>

                  <ul class="user-list mt-2" v-if="data.isLoading">
                    <chat-placeholder-component
                      v-for="i in [0, 1, 2]"
                      v-bind:key="i"
                    >
                    </chat-placeholder-component>
                  </ul>
                  <template v-if="data?.config?.inboxPos == 1">
                    <template v-if="getChatsDepartment.length > 0">
                      <!-- Left Chat Title -->
                      <div
                        class="left-chat-title d-flex justify-content-between align-items-center"
                      >
                        <div class="chat-title">
                          <h4>INBOX</h4>
                          <small style="font-size: 8pt"
                            >Chats que desejam conversar com o departamento do
                            qual você faz parte.</small
                          >
                        </div>
                      </div>
                      <!-- /Left Chat Title -->
                      <ul class="user-list mt-2">
                        <transition-group name="fade">
                          <chat-item-component
                            v-for="chat in getChatsDepartment"
                            v-bind:key="chat.id_api"
                            :chat="chat"
                            @setMessages="setMessages"
                            @closeWindowChat="closeWindowChat"
                            @getChats="data.getAllChatsWithMessages()"
                          >
                          </chat-item-component>
                        </transition-group>
                      </ul>
                    </template>
                    <template v-if="getChatsBot.length > 0 && !onlyGroups">
                      <!-- Left Chat Title -->
                      <div
                        class="left-chat-title d-flex justify-content-between align-items-center mt-0 ps-0 pe-0"
                      >
                        <div class="chat-title">
                          <h4>BOT</h4>
                        </div>
                      </div>
                      <!-- /Left Chat Title -->

                      <ul class="user-list mt-2">
                        <transition-group name="fade">
                          <chat-item-component
                            v-for="chat in getChatsBot"
                            v-bind:key="chat.id_api"
                            :chat="chat"
                            @setMessages="setMessages"
                            @closeWindowChat="closeWindowChat"
                            @getChats="data.getAllChatsWithMessages()"
                          >
                          </chat-item-component>
                        </transition-group>
                      </ul>
                    </template>
                  </template>
                  <!-- Left Chat Title -->
                  <div
                    class="left-chat-title d-flex justify-content-between align-items-center ps-0 pe-0"
                    v-if="!data.isLoading"
                  >
                    <div class="chat-title">
                      <h4>SEUS CHATS</h4>
                    </div>
                    <a
                      href="javascript:void(0);"
                      id="clickToShowAnnounces"
                      data-bs-toggle="modal"
                      data-bs-target="#announces"
                    ></a>
                  </div>
                  <!-- /Left Chat Title -->
                  <div
                    class="message-welcome-center"
                    v-if="
                      getMyChats.length === 0 &&
                      getChatsBot.length === 0 &&
                      !data.isLoading
                    "
                  >
                    <p>
                      Não há chats aguardando resposta.
                      <span class="text-highlight">Parabéns!</span>
                    </p>
                  </div>
                  <div
                    class="message-welcome-center"
                    v-if="getMyChats.length === 0 && getChatsBot.length > 0"
                  >
                    <p>
                      Não há chats aberto, porém
                      <span class="text-highlight"
                        >há chats sem atendimento.</span
                      >
                    </p>
                  </div>
                  <ul class="user-list mt-2">
                    <transition-group name="fade">
                      <chat-item-component
                        v-for="chat in getMyChats"
                        v-bind:key="chat?.id_api"
                        :chat="chat"
                        @setMessages="setMessages"
                        @closeWindowChat="closeWindowChat"
                        @getChats="data.getAllChatsWithMessages()"
                      >
                      </chat-item-component>
                    </transition-group>
                  </ul>
                  <template v-if="data?.config?.inboxPos != 1">
                    <template v-if="getChatsDepartment.length > 0">
                      <!-- Left Chat Title -->
                      <div
                        class="left-chat-title d-flex justify-content-between align-items-center ps-0 pe-0"
                      >
                        <div class="chat-title">
                          <h4>INBOX</h4>
                          <small style="font-size: 8pt"
                            >Chats que estão em seus setores, esperando
                            atendimento</small
                          >
                        </div>
                      </div>
                      <!-- /Left Chat Title -->

                      <ul class="user-list mt-2">
                        <transition-group name="fade">
                          <chat-item-component
                            v-for="chat in getChatsDepartment"
                            v-bind:key="chat.id_api"
                            :chat="chat"
                            @setMessages="setMessages"
                            @closeWindowChat="closeWindowChat"
                            @getChats="data.getAllChatsWithMessages()"
                          >
                          </chat-item-component>
                        </transition-group>
                      </ul>
                    </template>
                    <template v-if="getChatsBot.length > 0">
                      <!-- Left Chat Title -->
                      <div
                        class="left-chat-title d-flex justify-content-between align-items-center ps-0 pe-0"
                      >
                        <div class="chat-title">
                          <h4>BOT</h4>
                        </div>
                      </div>
                      <!-- /Left Chat Title -->

                      <ul class="user-list mt-2">
                        <transition-group name="fade">
                          <chat-item-component
                            v-for="chat in getChatsBot"
                            v-bind:key="chat.id_api"
                            :chat="chat"
                            @setMessages="setMessages"
                            @closeWindowChat="closeWindowChat"
                            @getChats="data.getAllChatsWithMessages()"
                          >
                          </chat-item-component>
                        </transition-group>
                      </ul>
                    </template>
                  </template>

                  <template v-if="search.length > 0">
                    <!-- Left Chat Title -->
                    <div
                      class="left-chat-title d-flex justify-content-between align-items-center ps-0 pe-0"
                    >
                      <div class="chat-title">
                        <h4>CONTATOS</h4>
                        <ContactsFilterComponent
                          :query="search"
                          @openChat="openChat"
                        />
                      </div>
                    </div>
                    <!-- /Left Chat Title -->
                  </template>
                </div>
              </perfect-scrollbar>
              <div class="newchat-btn" v-show="!isMobile()" @click="scrollDown">
                <div
                  role="button"
                  tabindex="0"
                  aria-label="Abrir nova conversa com contato"
                  class="sub"
                  data-bs-toggle="modal"
                  data-bs-target="#new-chat-with-contact"
                >
                  <i class="fas fa-plus"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Sidebar group -->
        <template v-if="!data.openedChat || data.openedChat?.isOpen == false">
          <div
            class="chat"
            id="welcome_message"
            v-if="!data.openedChat || data.openedChat?.isOpen == false"
          >
            <div class="chat-body">
              <div class="row mb-4" style="width: 99%">
                <Stat
                  bg-color="bg-danger"
                  :value="`${getChatsBot.length}`"
                  title="Chats no bot"
                  icon="fas fa-tired"
                />
                <Stat
                  bg-color="bg-danger"
                  :value="`${getChatsDepartment.length}`"
                  title="Chats aguardando"
                  icon="fas fa-tired"
                />
                <Stat
                  bg-color="bg-success"
                  :value="`${getMyChats.length + getChatsDepartment.length}`"
                  title="Chats em atendimento"
                  icon="fas fa-comment"
                />
                <Stat
                  :value="timeWaiting"
                  title="Tempo de resposta"
                  icon="fas fa-history"
                />
              </div>
              <div
                class="message-welcome-center"
                style="margin-top: 150px; height: 200px"
              >
                <img
                  src="../assets/img/status-icon.png"
                  class="rounded-circle"
                  height="75"
                  width="60"
                />
                <p>
                  Bem vindo ao <span class="text-highlight">Titan Chat!</span>
                </p>
              </div>
            </div>
          </div>
        </template>

        <!-- Chat -->
        <chat-layout
          v-show="data.openedChat?.isOpen === true"
          :chat_opened="data.openedChat"
          @callsendFile="callsendFile"
          @callsendLocation="callsendLocation"
          @callsendButtons="callsendButtons"
          @clearSearch="clearSearch"
          :hideActionsChat="allChats"
          @nullchatOpened="data.openedChat = null"
        ></chat-layout>
        <!-- /Chat -->

        <!-- Right sidebar -->
        <right-sidebar
          v-show="data.openedChat?.isOpen == true"
          :chat_opened="data.openedChat"
        >
        </right-sidebar>
        <!-- Right sidebar -->
      </div>
      <modal-transfer-layout @closeChat="data.openedChat = null" />
      <modal-new-chat-contact />
      <modal-new-contact @setMessages="setMessages" />
      <modal-announcement />
    </div>
  </div>
</template>
<style>
.nav-chat {
  cursor: pointer;
}
</style>
